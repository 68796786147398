 <template>
  <div id="LoanForm">
    <!-- 表单 -->
    <van-form ref="form">
      <custom-radio v-model="form.loanType" label="贷款类型" :columns="loanTypeDict" />
      <van-field required :rules="[{ required: true }]" v-model="form.loanReason" class="textarea-style" rows="3" label="申请理由" type="textarea" maxlength="300" placeholder="请输入贷款申请理由" show-word-limit />
      <van-field name="cdbOrNot" v-if="form.loanType ==='syd'" label="是否国开行">
        <template #input>
          <van-switch v-model="form.cdbOrNot" :active-value="true" :inactive-value="false" active-color="#67C23A" size="20" />
        </template>
      </van-field>
      <van-field v-if="form.cdbOrNot && form.loanType ==='syd'" :rules="[{ required: true }]" v-model="form.receiptCheckCode" placeholder="校验回执码" label="校验回执码" />
      <van-cell-group title="每年贷款">
        <van-field required :rules="[{ validator: validMndk,message: '请核对贷款金额' }]" v-model="form.annualAccomAmount" placeholder="请填写每年住宿费（元）" @input="calculateTotal" label="每年住宿费(元)" />
        <van-field required :rules="[{ validator: validMndk,message: '请核对贷款金额'  }]" v-model="form.annualLivingAmount" placeholder="请填写每年生活费（元）" @input="calculateTotal" label="每年生活费(元)" />
        <van-field required readonly v-model="form.annualLoan" label="每年贷款总额" />
      </van-cell-group>
      <van-cell-group title="" v-if="form.loanType ==='xyd'">
        <van-field required label="贷款期限" :rules="[{ required: true }]">
          <template #input>
            <van-stepper v-model="form.loanTime" integer @change="calculateTotal" />
          </template>
        </van-field>
        <van-field v-model="form.loanAmount" readonly label="贷款总额" />
      </van-cell-group>
    </van-form>
    <div class="submit-button shadow-style">
      <van-button round block type="primary" :loading="loading" native-type="submit" @click="doSubmit">保 存</van-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { add, edit } from "@/api/modules/student/loan";
import { getconfig } from "@/api/modules/config/cspz";

export default {
  name: "LoanForm",
  components: {},
  data() {
    return {
      title: "新增",
      loading: false,
      customRuleFileds: ["loanType"],
      loanTypeDict: [
        { label: "生源地", value: "syd" },
        { label: "校园地", value: "xyd" },
      ],
      form: {
        id: "",
        loanType: "syd",
        cdbOrNot: false,
        loanReason: "",
        receiptCheckCode: "",
        annualAccomAmount: "",
        annualLivingAmount: "",
        annualLoan: "",
        loanAmount: "",
        graConfirm: null,
        loanTime: 12,
      },
      syddksqksrq: "", //校园地贷款申请开始日期
      syddksqjsrq: "", //校园地贷款申请结束日期
      xyddksqksrq: "", //校园地贷款申请开始日期
      xyddksqjsrq: "", //校园地贷款申请结束日期
      syddkzxdkje: "", //生源地贷款最小贷款金额
      syddkzddkje: "", //生源地贷款最大贷款金额
      xyddkzxdkje: "", //校园地贷款最小贷款金额
      xyddkzddkje: "", //校园地贷款最大贷款金额,
      configData: [
        "syddksqksrq",
        "syddksqjsrq",
        "xyddksqksrq",
        "xyddksqjsrq",
        "syddkzxdkje",
        "syddkzddkje",
        "xyddkzxdkje",
        "xyddkzddkje",
      ],
    };
  },
  computed: {
    ...mapGetters(["user", "roles"]),
  },
  created() {
    this.initData();
    getconfig({
      paracodes: this.configData.join(","),
    }).then((res) => {
      res.forEach((item) => {
        this.configData.forEach((param) => {
          if (param === item.paracode) {
            this.$set(this, param, item.paravalue);
          }
        });
      });
    });
  },
  methods: {
    initData() {
      this.form.stuNo = this.user.username;
      this.form.stuName = this.user.nickName;
      if (this.$route.query && this.$route.query.id) {
        let data = this.$route.query;
        document.title = "编辑贷款申请";
        this.$nextTick((e) => {
          this.form = data;
          this.form.cdbOrNot = data.cdbOrNot === "true" ? true : false;
        });
      } else {
        document.title = "新增贷款申请";
      }
    },
    onClickLeft() {
      this.resetForm();
      this.$router.go(-1);
    },
    // 计算贷款金额
    calculateTotal() {
      let annualAccomAmount = this.form.annualAccomAmount;
      let annualLivingAmount = this.form.annualLivingAmount;
      let loanTime = this.form.loanTime;
      if (!annualAccomAmount) annualAccomAmount = 0;
      if (!annualLivingAmount) annualLivingAmount = 0;
      if (!loanTime) loanTime = 1;
      this.form.annualLoan =
        parseInt(annualAccomAmount) + parseInt(annualLivingAmount);
      if (this.form.loanType === "xyd") {
        this.form.loanAmount = (
          (parseInt(annualAccomAmount) + parseInt(annualLivingAmount)) *
          (parseInt(loanTime) / 12)
        ).toFixed(2);
      }
    },
    //验证每年贷款金额是否在设置的范围内
    validMndk(value) {
      if (!value) {
        this.$toast("每年贷款必须填写");
        return false;
      }
      let val =
        parseInt(this.form.annualAccomAmount) +
        parseInt(this.form.annualLivingAmount);
      if (this.form.loanType === "syd") {
        console.log(this.form.loanAmount);
        if (val < parseInt(this.syddkzxdkje)) {
          this.$toast(
            "每年贷款金额不能小于最小贷款额度(" + this.syddkzxdkje + "元)"
          );
          return false;
        } else if (val > parseInt(this.syddkzddkje)) {
          this.$toast(
            "每年贷款金额不能超过最大贷款额度(" + this.syddkzddkje + "元)"
          );
          return false;
        }
      } else if (this.form.loanType === "xyd") {
        if (val < parseInt(this.xyddkzxdkje)) {
          this.$toast(
            "每年贷款金额不能小于最小贷款额度(" + this.xyddkzxdkje + "元)"
          );
          return false;
        }
        if (val > parseInt(this.xyddkzddkje)) {
          this.$toast(
            "每年贷款金额不能超过最大贷款额度(" + this.xyddkzddkje + "元)"
          );
          return false;
        }
      }
    },
    resetForm() {
      this.form = {
        id: "",
        loanType: "syd",
        cdbOrNot: "",
        loanReason: "",
        receiptCheckCode: "",
        annualAccomAmount: "",
        annualLivingAmount: "",
        annualLoan: "",
        loanAmount: "",
        graConfirm: false,
        loanTime: "",
      };
    },
    doSubmit() {
      this.$refs.form.validate().then(
        (res) => {
          // 验证通过数据提交
          this.$dialog
            .confirm({
              title: "提示",
              message: "你确定要进行保存操作吗？",
            })
            .then(() => {
              this.loading = true;
              if (this.$route.query && this.$route.query.id) {
                edit(this.form)
                  .then((res) => {
                    this.$notify({
                      type: "success",
                      message: "提交成功",
                      duration: 2000,
                    });
                    this.onClickLeft();
                    this.loading = false;
                  })
                  .catch((e) => {
                    this.loading = false;
                  });
              } else {
                add(this.form)
                  .then((res) => {
                    this.$notify({
                      type: "success",
                      message: "提交成功",
                      duration: 2000,
                    });
                    this.onClickLeft();
                    this.loading = false;
                  })
                  .catch((e) => {
                    this.loading = false;
                  });
              }
            })
            .catch(() => {
              // on cancel
            });
        },
        (err) => {
          this.$notify({
            type: "warning",
            message: "有提交项未填写！",
            duration: 1500,
          });
        }
      );
    },
  },
};
</script>

<style  lang='scss' scoped>
@import '~@/assets/styles/student/loan.scss';

::v-deep .van-field__label {
  width: 110px;
}
</style>
